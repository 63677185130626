import contracts from './contracts'
import tokens from './tokens'
import LPs from './lp'
import {
	FarmConfig,
	QuoteToken
} from './types'

const farms: FarmConfig[] = [
]


if(`${process.env.REACT_APP_CHAIN_ID}`==='56'){
	farms.push(


		{
			pid: 8,
			risk: 5,
	
			lpSymbol: LPs.DND_BUSD_LP_V2.lpSymbol,
			lpAddresses: LPs.DND_BUSD_LP_V2.lpAddresses,
	
			isV2: true,
	
			token: tokens.DND,
			quoteToken: tokens.busd,
		},
		{
			pid: 5,
			risk: 1,
	
			isTokenOnly: true,
	
			lpSymbol: "DND",
			lpAddresses: LPs.DND_BUSD_LP.lpAddresses,
	
			token: tokens.DND,
			quoteToken: tokens.busd,
		},


		
		{
			pid: 6,
			risk: 1,
	
			isTokenOnly: true,
	
			lpSymbol: "WBNB",
			lpAddresses: LPs.BNB_BUSD_LP.lpAddresses,
	
			token: tokens.wbnb,
			quoteToken: tokens.busd,
		},
		{
			pid: 7,
			risk: 1,
	
			isTokenOnly: true,
	
			lpSymbol: "BUSD",
			lpAddresses: LPs.DND_BUSD_LP.lpAddresses,
	
			token: tokens.busd,
			quoteToken: tokens.busd,
		},



	{
		pid: 0,
		risk: 5,
	
		lpSymbol: 'DND-BUSD LP V1',
		lpAddresses: {56: '0xc1ed125c2bb79a3cfe849119242a36eee868a7a9'},
	
		token: tokens.DND,
		quoteToken: tokens.busd,
	},
	{
		pid: 1,
		risk: 5,
	
		lpSymbol: 'DND-BNB LP V1',
		lpAddresses: {56: '0x3f8abfe996ec2ceb8acbb468eb2ae5dae2654ff6'},
	
		token: tokens.DND,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 2,
		risk: 1,
	
		lpSymbol: 'BNB-BUSD LP V1',
		lpAddresses: {56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f'},
	
		token: tokens.wbnb,
		quoteToken: tokens.busd,
	},
	{
		pid: 3,
		risk: 1,
	
		lpSymbol: 'USDC-BUSD LP V1',
		lpAddresses: {56: '0x680dd100e4b394bda26a59dd5c119a391e747d18'},
	
		token: tokens.usdc,
		quoteToken: tokens.busd,
	},
	{
		pid: 4,
		risk: 1,
	
		lpSymbol: 'CAKE-BNB LP V1',
		lpAddresses: {56: '0xa527a61703d82139f8a06bc30097cc9caa2df5a6'},
	
		token: tokens.cake,
		quoteToken: tokens.wbnb,
	}
	)
}


if(`${process.env.REACT_APP_CHAIN_ID}`==='97'){
	farms.push(


		{
			pid: 0,
			risk: 5,
	
			lpSymbol: "(Mock) DND",
			lpAddresses: LPs.DND_BUSD_LP_V2.lpAddresses,
	
			isV2: true,
			isTokenOnly: true,
	
			token: tokens.DND,
			quoteToken: tokens.busd,
		},
		{
			pid: 1,
			risk: 2,
	
			isTokenOnly: false,
	
			lpSymbol: "(Mock) DND-BUSD LP V2",
			lpAddresses: LPs.DND_BUSD_LP_V2.lpAddresses,
	
			token: tokens.DND,
			quoteToken: tokens.busd,
		},
		{
			pid: 2,
			risk: 1,
	
			isTokenOnly: false,
	
			lpSymbol: "(Mock) BUSD-WBNB LP V2",
			lpAddresses: LPs.BNB_BUSD_LP_v2.lpAddresses,
	
			token: tokens.wbnb,
			quoteToken: tokens.busd,
		}
	)
}


if(`${process.env.REACT_APP_CHAIN_ID}`==='1337'){
	farms.push(


		{
			pid: 0,
			risk: 5,
	
			lpSymbol: "(fake)DND",
			lpAddresses: LPs.DND_BUSD_LP_V2.lpAddresses,
	
			isV2: true,
			isTokenOnly: true,
	
			token: tokens.DND,
			quoteToken: tokens.busd,
		},
		{
			pid: 1,
			risk: 1,
	
			isTokenOnly: true,
	
			lpSymbol: "(fake)BUSD",
			lpAddresses: LPs.DND_BUSD_LP_V2.lpAddresses,
	
			token: tokens.busd,
			quoteToken: tokens.DND,
		},
		{
			pid: 2,
			risk: 1,
	
			isTokenOnly: true,
	
			lpSymbol: "(fake)WBNB",
			lpAddresses: LPs.BNB_BUSD_LP_v2.lpAddresses,
	
			token: tokens.wbnb,
			quoteToken: tokens.busd,
		}
	)
}



export default farms

/*







	

	
    isTokenOnly: true,

	{
		pid: 3,
		risk: 5,

		isTokenOnly: true,

		lpSymbol: contracts.LP2b.name,
		lpAddresses: contracts.LP2b,

		token: tokens.busd2,
		

		
		quoteToken: tokens.busd,
	},

	{
		pid: 4,
		risk: 5,

		isTokenOnly: true,

		lpSymbol: contracts.LP2b.name,
		lpAddresses: contracts.LP2b,

		token: contracts.EGG

		
		quoteToken: tokens.busd,
	},
*/