import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

export const stoneBg=`
color:white;
background-position: top left;
${process.env.REACT_APP_CHAIN_ID==='56'?`
background-image:url('${process.env.REACT_APP_MEDIA_ROOT}bg/stone1b.jpg');
background-size: 300px;
`:`
background-image:url('${process.env.REACT_APP_MEDIA_ROOT}bg/stone_testnet.jpg');
background-size: 300px;
`}
background-position: top left;
`;


const GlobalStyle = createGlobalStyle`

@font-face {
	font-family: 'BASKVILL';
	src: url('/font/BASKVILL.woff') format('woff');
}
@font-face {
	font-family: 'BenguiatStd';
	src: url('/font/BenguiatStd-Book.woff') format('woff');
	font-weight: normal;
}
@font-face {
	font-family: 'BenguiatStd';
	src: url('/font/BenguiatStd-Bold.woff') format('woff');
	font-weight: bold;
}
@font-face {
	font-family: 'BenguiatStd';
	src: url('/font/BenguiatStd-Medium.woff') format('woff');
	font-weight: 500;
}

  * {
    font-family: 'BenguiatStd','Kanit', sans-serif;
	line-height: 1.3em;
  }
  h1{
	font-weight:bold;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

	.green{
		color:#08e90f;
	}
	.red{
		color:#f00;
	}
	.bold{
		font-weight: bold;
	}
	.btn_emoji{
		cursor:pointer;
	}
	.btn_text{
		cursor:pointer;
		text-decoration: underline;
		transition:color 0.7s 0s;
	}
	.btn_text:hover{
		color:#5c5cff;
	}
	.tac{
		text-align:center;
	}
	.stonePanel{
		${stoneBg}
		border:1px solid #444;
		border-radius:20px;
		box-shadow:1px 1px 5px black;
		padding:20px 40px;
		margin-bottom:20px;
		box-shadow: 0 3px 7px black;
		h1{
			margin-bottom:10px;
			background-image:url('/dungeonMedia/ui/header.png');
			background-repeat: no-repeat;
			background-position: center bottom;
			text-align: center;
			padding-bottom: 12px;
			font-size: 20px;
			width: 100%;
		}
	}

  }

  
	@media (max-width: 700px) {
		html,body{
			font-size: 12px;
		}
		h1{
			font-size: 18px;
		}
	}

	#root>div>nav{
		${stoneBg}
		${process.env.REACT_APP_CHAIN_ID==='56'?`
			background-size: 200px;
		`:`
			background-size: 130px;
		`}
	}


	#root>div>div>div:nth-child(1){
		background: radial-gradient(circle, rgba(44,44,44,1) 0%, rgba(0,0,0,1) 100%);
	}
`

export default GlobalStyle
