import tokens from './tokens';
import {
	LPConfig,
	QuoteToken
} from './types'

//TODO : useprice by lp,not by farm
//useLP


const LPs = {
	BNB_BUSD_LP_v2:{//must have
		lpSymbol: 'BNB-BUSD LP V2',
		lpAddresses: {
			56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
			97: '0xf6d6e18022Da174d41E29B5569eB99836F9B1350',
			1337:'0xD8eF1AE0276C265f0AD7447C73b37eAaA6Ab4CB0'
		},
		isV2: true,
		token: tokens.wbnb,
		quoteToken: tokens.busd,
	},
	DND_BUSD_LP_V2:{//must have
		lpSymbol: 'DND-BUSD LP V2',
		lpAddresses: {
			56: '0x920ec686496883d01a95935d2e327ab743734675',
			97: '0x252c41759A35ADB9F225DC38FC29efcFE07f4F94',
			1337:'0x400054B34e6445E6eD15Baf2220570bb22255d95'
		},
		isV2: true,
		token: tokens.DND,
		quoteToken: tokens.busd,
	},




	DND_BUSD_LP:{
		lpSymbol: 'DND-BUSD LP V1',
		lpAddresses: {
			56: '0xc1ed125c2bb79a3cfe849119242a36eee868a7a9',
			97: '0x0000000000000000000000000000000070869413',
			1337:'0x97161E902934d22d754Cc669E8AE052b919Bf0f4'
		},
		isV2: false,
		token: tokens.DND,
		quoteToken: tokens.busd,
	},
	BNB_BUSD_LP:{
		lpSymbol: 'BNB-BUSD LP V1',
		lpAddresses: {
			56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
			97: '0x0000000000000000000000000000000070869413',
			1337:'0x0000000000000000000000000000000070869413'
		},
		isV2: false,
		token: tokens.wbnb,
		quoteToken: tokens.busd,
	},
	DND_BNB_LP:{
		lpSymbol: 'DND-WBNB LP V1',
		lpAddresses: {
			56: '0x3f8abfe996ec2ceb8acbb468eb2ae5dae2654ff6',
			97: '0x0000000000000000000000000000000070869413',
			1337:'0x0000000000000000000000000000000070869413'
		},
		isV2: false,
		token: tokens.DND,
		quoteToken: tokens.wbnb,
	},
}

const liveDeployer97 = {
	BNB_BUSD_LP_v2:{//must have
		lpSymbol: 'BNB-BUSD LP V2',
		lpAddresses: {
			97: '0xf09c8c82102F18D9F1B6Fe7a8885750BC9C9fCd6'
		},
		isV2: true,
		token: tokens.wbnb,
		quoteToken: tokens.busd,
	},
	DND_BUSD_LP_V2:{//must have
		lpSymbol: 'DND-BUSD LP V2',
		lpAddresses: {
			97: '0x4cba8E75658F6659590D6FaDfcfa99eFBa83CFC4'
		},
		isV2: true,
		token: tokens.DND,
		quoteToken: tokens.busd,
	},
}


let data;
if(process.env.REACT_APP_DEPLOYER==="live" && process.env.REACT_APP_CHAIN_ID==="97"){
	data=liveDeployer97;
}else{
	data=LPs;
}
//export default LPs
const data2=data;
export default data2;