import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { QuoteToken,LPConfig } from 'config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchLP = async (_lp:LPConfig) => {
	

      const lpAdress = _lp.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: _lp.token.address[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: _lp.quoteToken.address[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Token decimals
        {
          address: _lp.token.address[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: _lp.quoteToken.address[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        tokenDecimals,
      	quoteTokenDecimals
      ] = await multicall(erc20, calls)

	  const tokenBalance=new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals));
	  const quoteTokenBalance =new BigNumber(quoteTokenBalanceLP).div(new BigNumber(10).pow(quoteTokenDecimals));

	  //console.log(_lp.lpSymbol,tokenBalance.toNumber(),quoteTokenBalance.toNumber())

	  return new BigNumber(quoteTokenBalance).div(new BigNumber(tokenBalance));


	  //return tokenBalanceLP.div(quoteTokenBalanceLP);//.times(tokenDecimals).div(quoteTokenDecimals);
}

export default fetchLP
