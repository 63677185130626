import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getMasterChefAddress = ():string => {
  return addresses.masterChef[chainId]
}

export const getMulticallAddress = ():string => {
  return addresses.multiCall[chainId]
}



export const getDungeonLordAddress = ():string => {
  return addresses.lordV2[chainId]
}
export const getDseV1Address = ():string => {
  return addresses.DseV1[chainId]
}
export const getMetaDseAddress = ():string => {
  return addresses.MetaDse[chainId]
}
export const getDungeonRedeemAddress = ():string => {
  return addresses.V2Redeem[chainId]
}


export const getRelicLordAddress = ():string => {
	return addresses.relicLordV1[chainId]
}
export const getRelicRoundAddress = ():string => {
	return addresses.relicRound[chainId]
}
export const getRelicStoreAddress = ():string => {
	return addresses.relicStore[chainId]
}
export const getMarketAddress = ():string => {
	return addresses.market[chainId]
}

export const getSmithyAddress = ():string => {
	return addresses.smithy[chainId]
}



export const getDNDAddress = ():string => {
  return tokens.DND.address[chainId];
}
export const getWbnbAddress = ():string => {
  return tokens.wbnb.address[chainId];
}