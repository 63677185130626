
export enum QuoteToken {
  'BNB' = 'WBNB',
  'BUSD' = 'BUSD',
  
  'UST' = 'UST',

  'DND' = 'DND',
  'CAKE' = 'CAKE'
}

export interface Address {
	4?: string
	97?: string
	1337?: string
	56?: string
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
}

export interface FarmConfig {
  pid: number
  risk: number
  
  lpSymbol: string
  lpAddresses: Address
  isV2?:boolean

  token: Token
  quoteToken: Token

  multiplier?: string
  isTokenOnly?: boolean
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface LPConfig {
	lpSymbol: string
	lpAddresses: Address
	isV2?:boolean
	token: Token
	quoteToken: Token
}