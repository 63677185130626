import { useEffect, useState } from 'react';
import fetchLP from 'state/fetchLP';
import useBlock from 'hooks/useBlock';
import { QuoteToken,LPConfig } from 'config/constants/types'
import BigNumber from 'bignumber.js';

const useLPPrice=(_lp:LPConfig)=>{
	const [price,setPrice]=useState<BigNumber>(new BigNumber(0));
	const block=useBlock();
	useEffect(()=>{
		fetchLP(_lp).then((_num)=>{
			setPrice(_num);
		})
	},[block,_lp]);
	return price
}
export default useLPPrice;