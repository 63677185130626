const tokens = {


	DND: {
		symbol: 'DND',
		address: {
		  56: '0x14c358b573a4cE45364a3DBD84BBb4Dae87af034',
		  97: '0x933d8f4aBb78C04711a3537488818251a3F662E0',
		  1337: '0x35B216C89043490C5C5e90e3077088dDa8b60DC5',
		},
		decimals: 18,
		projectLink: process.env.REACT_APP_FARM_ROOT,
	  },

	wbnb: {
	  symbol: 'WBNB',
	  address: {
		56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
		97: '0x203bB5Ddc17ddBF413eE47829b4E4D29028725De',
		1337: '0xDa6aA8deD1d20DA7d7744581d02eb6962bfAbE2b',
	  },
	  decimals: 18,
	  projectLink: 'https://www.binance.org/',
	},

	busd: {
		symbol: 'BUSD',
		address: {
		  56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
		  97: '0x3887842C850A3392DA4dF888dA0EEe763B76B004',
		  1337: '0x12e1FB6987fC013384444B93902D46678f6f4DF1',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
	},





	arena: {
	  symbol: 'Arena',
	  address: {
		56: '0x2A17Dc11a1828725cdB318E0036ACF12727d27a2',
	  },
	  decimals: 18,
	  projectLink: 'https://www.arenaswap.com/',
	},


	cake: {
	  symbol: 'CAKE',
	  address: {
		56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
		97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
	  },
	  decimals: 18,
	  projectLink: 'https://pancakeswap.finance/',
	},
	ust: {
	  symbol: 'UST',
	  address: {
		56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
	  },
	  decimals: 18,
	  projectLink: 'https://mirror.finance/',
	},
	btcst: {
	  symbol: 'BTCST',
	  address: {
		56: '0x78650b139471520656b9e7aa7a5e9276814a38e9',
	  },
	  decimals: 17,
	  projectLink: 'https://www.1-b.tc/',
	},
	sushi: {
	  symbol: 'SUSHI',
	  address: {
		56: '0x947950bcc74888a40ffa2593c5798f11fc9124c4',
	  },
	  decimals: 18,
	  projectLink: 'https://sushi.com/',
	},
	comp: {
	  symbol: 'COMP',
	  address: {
		56: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
	  },
	  decimals: 18,
	  projectLink: 'https://compound.finance/',
	},
	eth: {
	  symbol: 'ETH',
	  address: {
		56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
	  },
	  decimals: 18,
	  projectLink: 'https://ethereum.org/en/',
	},
	beth: {
	  symbol: 'BETH',
	  address: {
		56: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
	  },
	  decimals: 18,
	  projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
	},
	mamzn: {
	  symbol: 'mAMZN',
	  address: {
		56: '0x3947B992DC0147D2D89dF0392213781b04B25075',
	  },
	  decimals: 18,
	  projectLink: 'https://mirror.finance/',
	},
	mgoogl: {
	  symbol: 'mGOOGL',
	  address: {
		56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
	  },
	  decimals: 18,
	  projectLink: 'https://mirror.finance/',
	},
	ltc: {
	  symbol: 'LTC',
	  address: {
		56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
	  },
	  decimals: 18,
	  projectLink: 'https://litecoin.org/',
	},
	usdc: {
	  symbol: 'USDC',
	  address: {
		56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
	  },
	  decimals: 18,
	  projectLink: 'https://www.centre.io/usdc',
	},
	dai: {
	  symbol: 'DAI',
	  address: {
		56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
	  },
	  decimals: 18,
	  projectLink: 'http://www.makerdao.com/',
	},
	ada: {
	  symbol: 'ADA',
	  address: {
		56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
	  },
	  decimals: 18,
	  projectLink: 'https://www.cardano.org/',
	},
	dot: {
	  symbol: 'DOT',
	  address: {
		56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
	  },
	  decimals: 18,
	  projectLink: 'https://polkadot.network/',
	},
	link: {
	  symbol: 'LINK',
	  address: {
		56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
	  },
	  decimals: 18,
	  projectLink: 'https://chain.link/',
	},
	usdt: {
	  symbol: 'USDT',
	  address: {
		97: '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
		56: '0x55d398326f99059ff775485246999027b3197955',
	  },
	  decimals: 18,
	  projectLink: 'https://tether.to/',
	},
	btcb: {
	  symbol: 'BTCB',
	  address: {
		56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
	  },
	  decimals: 18,
	  projectLink: 'https://bitcoin.org/',
	},
	xrp: {
	  symbol: 'XRP',
	  address: {
		56: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
		97: '0xa83575490d7df4e2f47b7d38ef351a2722ca45b9',
	  },
	  decimals: 18,
	  projectLink: 'https://ripple.com/xrp/',
	},
	atom: {
	  symbol: 'ATOM',
	  address: {
		56: '0x0eb3a705fc54725037cc9e008bdede697f62f335',
	  },
	  decimals: 18,
	  projectLink: 'https://cosmos.network/',
	},
	uni: {
	  symbol: 'UNI',
	  address: {
		56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
	  },
	  decimals: 18,
	  projectLink: 'https://uniswap.org/',
	},
	pbtc: {
	  symbol: 'pBTC',
	  address: {
		56: '0xed28a457a5a76596ac48d87c0f577020f6ea1c4c',
	  },
	  decimals: 18,
	  projectLink: 'https://ptokens.io/',
	}

}

const liveDeployer97 = {
	DND: {
		symbol: 'Mock DND',
		address: {
		  97: '0x6546ecCD19877c90cc2fAD12F56Ad07A0D8f21f4'
		},
		decimals: 18,
		projectLink: process.env.REACT_APP_FARM_ROOT,
	  },
	wbnb: {
	  symbol: 'Mock WBNB',
	  address: {
		97: '0x81266818f8462fB404B44a55372b45cAe098006A'
	  },
	  decimals: 18,
	  projectLink: 'https://www.binance.org/',
	},
	busd: {
		symbol: 'Mock BUSD',
		address: {
		  97: '0x9816784dD578Cc99CE636521108Fc0Da7CB52789'
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
	},
}

let data;
if(process.env.REACT_APP_DEPLOYER==="live" &&  process.env.REACT_APP_CHAIN_ID==="97"){
	data=liveDeployer97;
}else{
	data=tokens;
}


//export default tokens;
const data2=data;
export default data2;