import React, { useContext,useEffect,useState } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceDND } from 'state/hooks'
import { Menu as UikitMenu,MenuEntry } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const DNDPriceUsd = usePriceDND()
  const TranslateString = useI18n()

  /*
  const [config2,setConfig2]=useState(config)
  const [lastLang,setlastLang]=useState('zxc')
	useEffect(()=>{

		if(selectedLanguage.code===lastLang){
			return
		}
		setlastLang(selectedLanguage.code);

		console.log('set menu lang ',selectedLanguage.code,TranslateString(4000, 'zxc'))
		

			const newConfigs=[];
			for(let i=0,t=config.length;i<t;i++){
				const c:MenuEntry={
					label:'',
					text:'',
					iconPath:'',
					i18nCode:0,
					href:'/'
				}
				// eslint-disable-next-line
				for(const l in config[i]){
					c[l]=config[i][l];
				}
				if(c.i18nCode){
					c.text=TranslateString(c.i18nCode, c.label);
				}else{
					c.text=c.label;
				}
				if(config[i].items){
					c.items=[];
					// eslint-disable-next-line
					for(const l in config[i].items){
						const d={
							label:'',
							text:'',
							i18nCode:0,
							href:'/'
						}
						// eslint-disable-next-line
						for(const m in config[i].items[l]){
							d[m]=config[i].items[l][m]
						}
						if(d.i18nCode){
							d.text=TranslateString(d.i18nCode, d.label);
						}else{
							d.text=d.label;
						}
						c.items.push(d)
					}
				}
				newConfigs.push(c)
			}
			setConfig2(newConfigs)
			
	},[selectedLanguage,lastLang,TranslateString])
	*/

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      COINPriceUsd={DNDPriceUsd.toNumber()}
      links={config}
      priceLink="https://coinmarketcap.com/currencies/dungeonswap/"
      {...props}
    />
  )
}

export default Menu
