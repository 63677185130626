import { MenuEntry } from '@pancakeswap-libs/uikit'
import tokens from 'config/constants/tokens'


const chainId = parseInt(process.env.REACT_APP_CHAIN_ID);

const config_home: MenuEntry[] = [
  {
    label: 'Home',
	i18nCode:4000,
    text: 'Home',
	iconPath: '/images/dungeon/icon/home.png',
    href: '/',
  },
]
const config_farm: MenuEntry[] = [
  {
	  label: 'Trading Market',
	  i18nCode:4001,
	  text: 'Trading Market',
	  iconPath: '/images/dungeon/icon/Exchange.png',
	  href: `${process.env.REACT_APP_EXCHANGE_ROOT_v2}#/swap?inputCurrency=${tokens.busd.address[process.env.REACT_APP_CHAIN_ID]}&outputCurrency=${tokens.DND.address[process.env.REACT_APP_CHAIN_ID]}`
  },
  {
	  label: 'Liquidity Fountain',
	  i18nCode:4002,
	  text: 'Liquidity Fountain',
	  iconPath: '/images/dungeon/icon/Liquidity.png',
	  href: `${process.env.REACT_APP_EXCHANGE_ROOT_v2}#/pool`
  },

  {
	label: 'Fantasy Farm & Pool',
	i18nCode:4003,
	text: 'Fantasy Farm & Pool',
	iconPath: '/images/dungeon/icon/farm.png',
    href: '/farm',
  },
]

const config_2dungeon: MenuEntry[] = [
  {
    label: 'Classic Dungeon',
	i18nCode:4006,
    text: 'Classic Dungeon',
	iconPath: '/images/dungeon/icon/fight.png',
    href: '/dungeon',
  },
  {
    label: 'Ancient Dungeon (P2E)',
	i18nCode:4006,
    text: 'Ancient Dungeon (P2E)',
	iconPath: '/images/dungeon/icon/fight.png',
    href: '/AncientDungeon'
  },
]
/*
const config_old_dungeon: MenuEntry[] = [
	{
		label: 'The Dungeon',
		i18nCode:4006,
		text: 'The Dungeon',
		iconPath: '/images/dungeon/icon/fight.png',
		href: 'https://v1.dungeonswap.app/dungeon'
	}
]
*/
const config_other: MenuEntry[] = [
  {
    label: 'The Ancient Ruin',
	i18nCode:4013,
    text: 'The Ancient Ruin',
	iconPath: '/images/dungeon/icon/nft.png',
    href: '/relicStore'
  },
  {
    label: 'NFT Equipment',
	i18nCode:0,
    text: 'NFT',
	iconPath: '/images/dungeon/icon/ring.png',
    items: [
		{
			label: 'Armoury',
			i18nCode:4012,
			text: 'Armoury',
			iconPath: '/images/dungeon/icon/shield.png',
			href: '/armoury',
		  },
		  {
			  label: 'Redeem',
			  i18nCode:4013,
			  text: 'Redeem',
			  iconPath: '/images/dungeon/icon/cup.png',
			  href: '/redeem',
		  },
		  {
			  label: 'NFT Market',
			  i18nCode:4013,
			  text: 'NFT Market',
			  iconPath: '/images/dungeon/icon/Exchange.png',
			  href: '/market'
			},
		  {
			  label: 'Smithy',
			  i18nCode:4013,
			  text: 'Smithy',
			  iconPath: '/images/dungeon/icon/smith2.png',
			  href: '/smithy',
		  },
    ],
  },
  {
    label: 'Info',
	i18nCode:4008,
    text: 'Info',
	iconPath: '/images/dungeon/icon/info.png',
    items: [
		
		{
			label: 'Documentation',
			i18nCode:4007,
			text: 'Documentation',
			iconPath: '/images/dungeon/icon/documentation.png',
		  href: 'https://dsmods.gitbook.io/docs_dungeonswap/',
		},
		{
		  label: 'Audit',
		  text: 'Audit',
		i18nCode:4009,
		iconPath: '/images/dungeon/icon/white.png',
			href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/DungeonSwap.pdf',
		  },
			{
			  label: 'Tokenomics',
			  text: 'Tokenomics',
			i18nCode:4009,
			iconPath: '/images/dungeon/icon/audit.png',
				href: 'https://dsmods.gitbook.io/docs_dungeonswap/wiki/tokenomics-a-sustainable-gamefi-design',
			  },
			{
		  label: 'GitHub',
		  text: 'GitHub',
		i18nCode:4010,
		iconPath: '/images/dungeon/icon/git.png',
		  href: 'https://github.com/dungeonswap/DungeonSwap-contracts/tree/main/contracts',
		},
    ],
  },
]

/*


*/

let temp_config;
if(chainId===56){
	temp_config=[...config_home,...config_farm,...config_2dungeon,...config_other]
}else{
	temp_config=[...config_home,...config_2dungeon,...config_other]
}
const full_config=temp_config;

export default full_config;//[...config1,...config2,...config3]