import React, { useState, useEffect, useRef ,useCallback} from 'react'
import { getWeb3 } from 'utils/web3'

const BlockContext = React.createContext(0)

const web3 = getWeb3()

const BlockContextProvider = ({ children }) => {
  const previousBlock = useRef(0)
  const [block, setBlock] = useState(0)


  const renewBlockNumber=useCallback(async () => {
	const blockNumber = await web3.eth.getBlockNumber()
	if (blockNumber !== previousBlock.current) {
	  previousBlock.current = blockNumber
	  setBlock(blockNumber)
	}
  },[previousBlock])

  useEffect(() => {
	renewBlockNumber()
    const interval = setInterval(renewBlockNumber, 3000)
    return () => clearInterval(interval)
  }, [renewBlockNumber])

  return <BlockContext.Provider value={block}>{children}</BlockContext.Provider>
}

export { BlockContext, BlockContextProvider }
