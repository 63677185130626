import useLPPrice from 'hooks/useLPPrice'
import tokens from './tokens';

const realdata={
	
	multiCall: {
		56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
		97: '0x8BbeD55A782d8b5B0A3163D53a22Ba7077E2F8b6',
		1337:'0xfDFe414054b40fda4187Eda2Fe76b8EE92383e6B'
	},

	masterChef: {
		56: '0x3720F1F9a02BFB4dD6afb9030eB826B4392D321F',
		97: '0x5C32660A9bf95FcC8535c9f585251E757B8d122a',
		1337:'0x3D955B60A1547BACc7d75c727Cc2558FA9d92abd'
	},

	DseV1: {
		56: '0x455d4b2C4b4b3e50B3c3d9F8160956aA1d32936D',
		97: '0xf65E8Eb87123d809f88f274Aa5E5876885F55275',
		1337:'0x9f6a90E5734A9Cf9AD86c1e5C9B735F84Dd3d5A6'
	},

	//=====================
	

	MetaDse: {
		56: '0xf0eC791A9F9d47D62B763D936FC4326d129d5b74',
		97: '0x4D43b7c84D84D6AbcFdB18C01A8c23763aE37CBa',
		1337:'0x16C0027B9Ff30565d680777f4b600C76cecc0795'
	},


	V2Redeem: {
		56: '0xDDa595665b1C0622BFC5ee0f3315Ff7c6e0b0Ea1',
		97: '0x3f5A5f27f60f85b63145Bc660Aa169640e989b51',
		1337:'0x0fB8828c530F72635113f56a436a8121B6E57826'
	},

	lordV2: {
		56: 	'0x82a7a50545e91D73EA0020D61401C73DE1d8FB12',
		97: '',
		1337:'0x2169dC58687476AA88494aB155C86Df81e9f8a2F'
	},

	//=====================
	
	relicLordV1: {
		56: 	'0x6Ba8cce60dBE5135f7d71770f6E5c67828132d1A',
		97: '',
		1337:'0xA9aCAb9B3Fb867d275A6EEBD1F9684A9CFc2c3DD'
	},

	relicRound: {
		56: 	'0xd440C3aa9b4A5559512659c0aCe31D66e06b60E2',
		97: '',
		1337:'0x8095a0f1E538b6e7f1d0F7DFcBB68c842B667893'
	},

	relicStore: {
		56: '0x5f23884A9A01670ee0e443973E47956CadA76fa4',
		97: '',
		1337:'0x568E6689E252f1679C96090031306188Dfb7d4CE'
	},

	metaHelper: {
		56: '0x6bD03385682Fcc6Db53635d9c12325c2f4B8B127',
		97: '0xc1eA83FC74aC7E7F4F7f3f90d46d6214F09b5665',
		1337:'0xd92fcbC097a0093bD0165D338668DC3313120210'
	},

	market: {
		56: '0x86370ac19Dc25c1AD4956D71c0fecb534A9FBD02',
		97: '',
		1337:'0x2D16f1260A330B981FcFc0B726a57f63706d72e8'
	},

		username: {
			56: '',
			97: '0x895923485d76Cf15E222Fa7E3c6f4D228071cEB6',
			1337:'0x01ba7691C8445683a1D1f51f0f772DA6f36bF6f1'
		},

		factory: {
			56: '',
			97: '',
			1337:'0x3D8e2f9004AE51889b1fD135424202A82684D6CE'
		},

	smithy: {
		56: '0x2C2Dd3815E63B740bEdf2DDFdf4dB169da29Ff36',
		97: '0x20e8107DDBC3E3C7FD77cf5F738D6bF4196adA1B',
		1337:'0x70Fa1B6AA279c23f30DC6FE38058447f72eCA0e8'
	},
}

/*DND
		  56: '0x14c358b573a4cE45364a3DBD84BBb4Dae87af034',
		  97: '0x933d8f4aBb78C04711a3537488818251a3F662E0',
		  1337: '0x35B216C89043490C5C5e90e3077088dDa8b60DC5',
*/
//56mocktoken:0x0fa6E2af614B76093eb47aB169a7Ec8cB7580B16


//MDND	0x6546ecCD19877c90cc2fAD12F56Ad07A0D8f21f4
const liveDeployer97={
	
	multiCall: {
		97: '0x8BbeD55A782d8b5B0A3163D53a22Ba7077E2F8b6',
	},

	masterChef: {
		97: '0x79560D94aeE8afd36D79944593c02c50eb4d8F8a',
	},

	DseV1: {
		97: '0x52D31A3e7067B4D0C8b16FF1FAc72f81377A6e98',
	},

	MetaDse: {
		97: '0x4666Edccb81662253c3375B8BF444a1F24dd6611',
	},

	V2Redeem: {
		97: '0xf54bcEad1116a38cd5aBd20C80b66A0A5423a4D3',
	},

	relicStore: {
		97: '0x8a242E683F87bBCF3245D02168CE977aAA203098',
	},

	metaHelper: {
		97: '0xe2A38ac3bA3f1939AA75513A8696356A9e1F74B7',
	},



	lordV2: {
		97: '0x96b5240706F4B6AAc8b610077CEE33a637b7d744',
	},
	
	relicLordV1: {
		97: '0xDe84aA9c59E1247c601713862C831d3a74d58269',
	},

	relicRound: {
		97: '0x8c2F7005C8629A36C2E0CAA24339425C15491b2E',
	},


	market: {
		97: '0x1fAD267Ce3a850d42a0170733A4E8a3045F4E6Ad',
	},

	smithy: {
		97: '0x35eb64065252b66C1C9dD5DffA5c23361B4DBBc2',
	},

	username: {
		97: '',
	},
}


let data;
if(process.env.REACT_APP_DEPLOYER==="live" && process.env.REACT_APP_CHAIN_ID==="97"){
	data=liveDeployer97;
}else{
	data=realdata;
}
const data2=data;
export default data2;