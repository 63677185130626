import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
// import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Dungeon = lazy(() => import('./Dungeon'))
const NFTListPage = lazy(() => import('./Dungeon/NFTListPage'))

const RedeemPage = lazy(() => import('./Dungeon/Redeem'))
const SmithyPage = lazy(() => import('./Dungeon/Smithy'))
const MarketPage = lazy(() => import('./Dungeon/Market'))
//const MarketPage = lazy(() => import('./Dungeon/TBCMarketPage'))
const RelicStorePage = lazy(() => import('./Dungeon/RelicStore'))

const AncientDungeon = lazy(() => import('./Dungeon/Relic/AncientDungeon'))

//const AirdropPage = lazy(() => import('./Dungeon/Airdrop'))
//const RelicAirdropPage = lazy(() => import('./Dungeon/Airdrop/RelicAirdropPage'))
// const Pools = lazy(() => import('./views/Pools'))

//const Boss = lazy(() => import('./Dungeon/Boss/'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Ifos = lazy(() => import('./views/Ifos'))
// const Nft = lazy(() => import('./views/Nft'))

const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>

            <Route path="/armoury">
              <NFTListPage/>
            </Route>



			

			<Route path="/redeem">
              <RedeemPage/>
            </Route>



            <Route path="/" exact>
              <Home />
            </Route>
			
            <Route path="/farm">
              <Farms />
            </Route>
			
            <Route path="/dungeon">
              <Dungeon/>
            </Route>
			
            <Route path="/AncientDungeon">
              <AncientDungeon/>
            </Route>
	
            <Route path="/relicStore">
              <RelicStorePage/>
            </Route>
	
				<Route path="/market">
				<MarketPage/>
				</Route>

				<Route path="/smithy">
				<SmithyPage/>
				</Route>
			{/*

			


			
				<Route path="/airdrop">
				<AirdropPage/>
				</Route>	

            <Route path="/relicAirdrop">
              <RelicAirdropPage/>
            </Route> 

            Redirect
            404
  			 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      {/* <NftGlobalNotification /> */}
    </Router>
  )
}

export default React.memo(App)
